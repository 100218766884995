.side {
  border: 1px solid black;
}

/* body {
  overflow: hidden;
} */
/* img {
width: 100%;
height: 100%;
  
} */
.sc-cSHVUG {
  position: absolute;
}
.sc-brqgnP {
  position: absolute;
}
rect,svg{
  pointer-events: none;
  cursor: pointer;
  user-select: none;
}
svg > path {
  pointer-events: all;
}