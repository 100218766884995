* {
    box-sizing: border-box;
  }
  
  #myInput {
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0 auto;
    max-width: 1171px;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd !important;
    margin-bottom: 12px;
  }
  
  #myTable {
    border-collapse: collapse;
    max-width: 1176px;
    border: 1px solid #ddd;
    font-size: 18px;
    width: 100%;
    margin: 10px auto;
  }
  
  #myTable th, #myTable td {
    text-align: left;
    padding: 12px;
  }
  
  #myTable tr {
    border-bottom: 1px solid #ddd;
  }
  #myTable th
  {
      color:rgb(51 83 109);
      font-weight: bold;
  }
  

  #myTable tr.header, #myTable tr:hover {
    background-color: #D3D3D3;
}
