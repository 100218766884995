@-webkit-keyframes borderBlink {    
    from, to {    
        border-color: transparent    
    }    
    50% {    
        border-color: red    
    }    
}    
@keyframes borderBlink {    
    from, to {    
        border-color: transparent    
    }    
    50% {    
        border-color: red    
    }    
}    

.borderBlink {    
    -webkit-animation: borderBlink 3s step-end infinite;    
    animation: borderBlink 3s step-end infinite;    
}