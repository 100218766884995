.Background{
    height: 100vh;
    width: 100%;
    background: url('../../assets/images/landingBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}

.felix-logo{
    height: 60.2px;
    width: 160px;
    position: fixed;
    top: 0;
    left: 55px;
    margin-top: 15px;
}

.text-div{
    position: absolute;
    top: 45%;
    left: 13%;
    padding: 0;
}

.text-div p{
    padding: 0;
    margin-top: -30px;
    font-size: 45px;
    font-weight: bold;
    color:#0ed4ee
}

h1 {
    font-size: 44px;
    font-weight: bold;
}

.getStarted {
    color: #fff;
    padding: 9px;
    border-radius: 32px;
    width: 100%;
    font-weight: bold;
    font-size: 21px;
    outline: none !important;
    margin-top: 10px;
    padding: 11px;
    outline: none !important;
    border:none !important;
    background-image: linear-gradient(#0ed4ee,#05444c) !important;

    
}

.getStarted:hover{
    outline: none !important;
    border:none !important;
    background-image: linear-gradient(#0ed4ee,#05444c) !important;
}

.getStarted:focus{
    outline: none !important;
    border: none !important;
    background-image: linear-gradient(#0ed4ee,#05444c) !important;
}

.getStarted:active{
    outline: none !important;
    border:none !important;
    background-image: linear-gradient(#0ed4ee,#05444c) !important;

}