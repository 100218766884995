.grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.navbar {
  background-color:#D3D3D3 !important;
}

.navbar-dark .navbar-brand {
  margin-left: 23px !important;
}